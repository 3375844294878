@use 'styles/variables' as var;

.main {
  padding-top: 72px; // Offset the height of the fixed mobile header

  &[data-no-header='true'] {
    padding-top: 0;
  }
}

.footer {
  align-items: center;
  border-top: 1px solid #eaeaea;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 2rem 0;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

/* Copied from MUI styling to create custom max width container */
.max-width-container {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  max-width: var.$maximum-site-width;

  // Not copied from MUI
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &[data-narrow='true'] {
    max-width: var.$maximum-site-width-narrow;
  }

  &[data-align-content-bottom='true'] {
    justify-content: flex-end;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  justify-content: space-between;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: 1024px) {
  .main {
    padding-top: 96px; // Offset the height of the fixed desktop header
  }

  .main[data-show-progress='true'] {
    padding-top: 100px; // Offset the height of the fixed desktop header
  }
}
