/*
Ascend variables are imported here and shouldn't be imported in any other
modules and all Ascend variables can be made available in a components
component.module.scss file by including this file like:

`@use styles/variables` as var;
  NOTE: Be sure to use @use and not @import since doing so increases the bundle
  size while @use only loads a file once

- Using a variable:
  `color: var.$color-neutral-background-medium;`

TODO: remove placeholder variables when Ascend exposes them in SCSS.
Ascend repository: https://bitbucket.org/billsdotcom_bills/ascend/src/main/
*/
@import '@achieve/ascend/dist/variables';

/* Spacing (replace when exposed in Ascend) */
$spacing: 8px;

/* Animation timing */
$default-animation-timing: 250ms cubic-bezier(0.4, 0, 0.2, 1); // matches defaults for mui components
$half-animation-timing: 125ms cubic-bezier(0.4, 0, 0.2, 1);

/**
 * Constants based on grid specs in Ultramarine
 * https://www.figma.com/file/tn1r1b5fs14dD0kFLSk4mL/ULTRAMARINE-Achieve-Design-System?node-id=21%3A92
 */

/* Maximum site container widths */
$maximum-site-width: 1920px;
$maximum-site-width-narrow: 1440px;

/* Maximum site content widths */
$maximum-site-content-width: 1440px;
$maximum-site-content-width-narrow: 1280px;

/* Grid gutter width constants */
$default-gutter-width: $spacing * 2; // 16px
$medium-and-up-gutter-width: $spacing * 4; // 32px

/* Border radius */
$achieve-default-border-radius: 10px;
$achieve-border-radius-2: 12px;

/* Card component family border radius */
$card-border-radius: 20px;
$card-mobile-border-radius: 10px;

$eyebrow-letter-spacing: 0.12em;

$article-body-font: 'Ultramarine', roboto, helvetica, arial, sans-serif;
$article-body-font-size: 15px;

//Breakpoints to use with max-width instead of min-width
// example: @media (max-width: $breakpoint-max-md) {}

$breakpoint-min-xxs: 0;
$breakpoint-min-xs: 319px;
$breakpoint-min-sm: 479px;
$breakpoint-min-md: 767px;
$breakpoint-min-lg: 1023px;
$breakpoint-min-xl: 1279px;
$breakpoint-min-xxl: 1439px;
