@use 'styles/functions' as fn;
@use 'styles/variables' as var;

.tcb-banner {
  background-color: var.$color-neutral-0;
  box-shadow: 0 6px 40px -4px rgb(16 24 40 / 11%), 0 9px 12px -3px rgb(16 24 40 / 5%);
  position: fixed;
  bottom: 0;
  display: flex;
  z-index: 10; // less than header/mobile nav
  max-width: 1440px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.button-padding {
  padding-right: fn.spacing(3);
  padding-left: fn.spacing(3);
}

.tcb-content {
  padding: fn.spacing(1) fn.spacing(5.5) fn.spacing(1) fn.spacing(2);
  text-align: justify;
}

.tcb-btn {
  float: right; /* shape-outside only apply to float elements */
  height: 100%; /* take all the height */
  margin-left: fn.spacing(2);
  margin-right: fn.spacing(-4);

  /* push the image to the bottom */
  display: flex;
  align-items: flex-end;
  shape-outside: inset(calc(100% - 36px) 0 0);
}

.tcb-x {
  cursor: pointer;
  position: absolute;
  top: fn.spacing(1.5);
  right: fn.spacing(1.5);
}

@media screen and (min-width: var.$breakpoint-md) {
  .tcb-content {
    padding: fn.spacing(4) fn.spacing(5.5) fn.spacing(4);
  }
}

@media screen and (min-width: var.$breakpoint-lg) {
  .tcb-content {
    gap: fn.spacing(4);
  }

  .tcb-btn {
    margin-top: 0;
  }
}

@media screen and (min-width: '940px') and (max-width: var.$breakpoint-lg) {
  .tcb-btn {
    margin-top: fn.spacing(1.5);
  }
}
